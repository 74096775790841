import React, { useEffect } from "react";
import { Navigation } from "./navigation";

import { useLocation } from "react-router-dom";

export const TermsAndCondition = (props) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isMobileApp = searchParams.get("mobile") || 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isMobileApp != 1 && <Navigation whiteBg={true} />}
      <div
        id="terms-condition-privacy"
        className={`container ${isMobileApp == 1 ? "container-mobileapp" : ""}`}
      >
        <div className="row" id="terms-and-condition">
          <div className="left-nav">
            <div className="row">
              <div className="left-nav-title terms">
                <a
                  className="collapsed sidebar-title"
                  data-toggle="collapse"
                  data-target="#terms-sidebar"
                  aria-expanded="false"
                  aria-controls="terms-sidebar"
                  type="button"
                >
                  Terms & Policies
                </a>
              </div>
              <div id="terms-sidebar" className="collapse">
                <div>
                  <a href="#terms-of-use">Terms of Use</a>
                </div>
                <div>
                  <a href="#acceptable-use-policy">Acceptable Use Policy</a>
                </div>
                <div>
                  <a href="#copyright-trademark">
                    Copyright and Trademark Policy
                  </a>
                </div>
                <div>
                  <a href="#payments-and-refund">Payments and Refund Policy</a>
                </div>
                {/* <div>
                  <a href="#honor-code">
                    Honor Code
                  </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-lg-offset-3 col-sm-12 col-md-12 col-xs-12 mb-4">
            {!isMobileApp && <div id="terms-of-use" className="terms-spacer" />}
            <h3>Terms of Use </h3>
            <h5>Eﬀective: November 1, 2024.</h5>

            <div>
              <p>Thank you for using Ace PDG!</p>
              <p>
                Ace PDG oﬀers products and services provided by Ace Military
                Apps LLC, a limited liability company incorporated in the State
                of Wyoming (collectively, "Ace Military Apps," “Ace PDG,” "us,"
                "we," or "our"). These Terms of Use ("Terms") govern your use of
                our website, apps, and other products and services ("Services").
                As some of our Services may be software that is downloaded to
                your computer, phone, tablet, or other device, you agree that we
                may automatically update this software, and that these Terms
                will apply to such updates. Please read these Terms carefully,
                and contact us if you have any questions, requests for
                information, or complaints. By clicking “I accept” (or for those
                who have not yet created an account, by using our Services), you
                agree to be bound by these Terms, including the policies
                referenced in these Terms. By using our Services, you agree to
                be bound by these Terms, including the policies referenced in
                these Terms.
              </p>
              <p>
                <b>
                  THESE TERMS INCLUDE AN ARBITRATION AGREEMENT, JURY TRIAL
                  WAIVER AND CLASS ACTION WAIVER THAT APPLY TO ALL CLAIMS
                  BROUGHT AGAINST Ace PDG . PLEASE READ THEM CAREFULLY AS THEY
                  AFFECT YOUR LEGAL RIGHTS: (1) YOU WILL ONLY BE PERMITTED TO
                  PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF AGAINST US ON AN
                  INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                  CLASS OR REPRESENTATIVE ACTION OR PROCEEDING AND YOU WAIVE
                  YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
                  CLASS-WIDE ARBITRATION; AND (2) YOU ARE WAIVING YOUR RIGHT TO
                  PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF IN A COURT OF LAW
                  AND TO HAVE A JURY TRIAL. THE TERMS OF THE ARBITRATION
                  AGREEMENT SHALL NOT APPLY IF UNENFORCEABLE UNDER THE LAWS OF
                  THE COUNTRY IN WHICH YOU RESIDE.
                </b>
              </p>
              <ol>
                <li>
                  Using Ace PDG
                  <p>Who May Use Our Services</p>
                  <p>
                    <b>
                      Any use or access by anyone under the age of 13 is
                      strictly prohibited.
                    </b>
                  </p>
                  <p>Additionally, you may use our Services only if you:</p>
                  <ul>
                    <li>can form a binding contract with Ace PDG;</li>
                    <li>
                      comply with these Terms, all applicable laws, and our
                      policies (including the Acceptable Use Policy, Honor Code,
                      rank-specific eligibility requirements, and any other
                      policies that may be applicable from time to time
                      (“Policies”)); and
                    </li>
                    <li>
                      are over the age at which you can provide consent to data
                      processing under the laws of your country. Certain regions
                      and Content Oﬀerings may have additional requirements
                      and/or diﬀerent age restrictions.
                    </li>
                  </ul>
                  <p>
                    Any violation of our Terms, applicable laws, or Policies may
                    result in your access to all or part of the Services being
                    suspended, disabled, or terminated.
                  </p>
                  <p>
                    When you create your Ace PDG account, and when you
                    subsequently use certain features, you must provide us with
                    accurate and complete information, and you agree to update
                    your information to keep it accurate and complete.
                  </p>
                  <p>Our License to You</p>
                  <p>
                    Subject to these Terms and our Policies, we grant you a
                    limited, personal, non-exclusive, non-transferable, and
                    revocable license right to use our Services. The rights
                    granted herein are only for your personal, non-commercial
                    use, unless you obtain our written permission otherwise. You
                    also agree that you will create, access, and/or use only one
                    user account, unless expressly permitted by Ace PDG, and you
                    will not share access to your account or access information
                    for your account with any third party. Using our Services
                    does not give you ownership of or any intellectual property
                    rights in our Services or the content you access.
                  </p>
                  <p>Commercial Use</p>
                  <p>
                    Any use of our Services for commercial purposes is strictly
                    prohibited. Any commercial use must be subject to a separate
                    agreement with Ace Military Apps, Inc. If you are an
                    organization already using our Services, separate terms
                    apply.
                  </p>
                </li>
                <li>
                  Content Oﬀerings
                  <p>Changes to Content Oﬀerings</p>
                  <p>
                    Ace PDG oﬀers Study flash cards and outlines, Quizzes, Mock
                    Exams and general content ("Content Oﬀerings") from public
                    sources published by the United States Air Force. While we
                    seek to provide world-class Content Oﬀerings, unexpected
                    events do occur. Ace PDG reserves the right to cancel,
                    interrupt, reschedule, or modify any Content Oﬀerings, or
                    change the point value or weight of any assignment, quiz, or
                    other assessment. Content Oﬀerings are subject to the
                    Disclaimers and Limitation of Liability sections below.
                  </p>
                  <p>No Academic Credit</p>
                  <p>
                    Ace PDG does not grant academic credit for the completion of
                    Content Oﬀerings. Unless otherwise explicitly indicated by a
                    credit-granting institution, participation in or completion
                    of Content Oﬀerings does not confer any academic credit.
                    Even if credit is awarded by one institution, there is no
                    presumption that other institutions will accept that credit.
                    Ace PDG has no obligation to have Content Oﬀerings
                    recognized by any educational institution or accreditation
                    organization.
                  </p>
                </li>
                <li>
                  Your Content
                  <p>User Content</p>
                  <p>
                    The Services enable you to share your content, such as Mock
                    Exam results ("User Content"), with Ace PDG and/or other
                    users. You retain all intellectual property rights in, and
                    are responsible for, the User Content you create and share.
                    User Content does not include course content or other
                    materials made available on or placed on the Ace PDG
                    platform.
                  </p>
                  <p>How Ace PDG May Use User Content</p>
                  <p>
                    To the extent that you provide User Content, you grant Ace
                    PDG a fully transferable, royalty-free, perpetual,
                    sublicensable, non-exclusive, worldwide license to copy,
                    distribute, modify, create derivative works based on,
                    publicly perform, publicly display, and otherwise use the
                    User Content. We reserve the right to remove or modify User
                    Content for any reason, including User Content that we
                    believe violates these Terms or other policies including our
                    Acceptable Use Policy and Code of Conduct.
                  </p>
                  <p>Feedback</p>
                  <p>
                    We welcome your suggestions, ideas, comments, and other
                    feedback regarding the Services ("Feedback"). By submitting
                    any Feedback, you grant us the right to use the Feedback
                    without any restriction or any compensation to you. By
                    accepting your Feedback, Ace PDG does not waive any rights
                    to use similar or related Feedback previously known to Ace
                    PDG, developed by our founders, contractors, or obtained
                    from other sources.
                  </p>
                </li>
                <li>
                  Security
                  <p>
                    We care about the security of our users. While we work to
                    protect the security of your account and related
                    information, Ace PDG cannot guarantee that unauthorized
                    third parties will not be able to defeat our security
                    measures. Please notify us immediately of any compromise or
                    unauthorized use of your account by emailing
                    acepdgapp@gmail.com.
                  </p>
                </li>
                <li>
                  Copyright and Trademark
                  <p>
                    Ace PDG respects the intellectual property rights of our
                    users, and other third parties and expects our users to do
                    the same when using the Services. We have adopted and
                    implemented the Ace PDG Copyright and Trademark Policy below
                    in accordance with applicable law, including the Digital
                    Millennium Copyright Act.
                  </p>
                </li>

                <li>
                  {" "}
                  Education Research
                  <p>
                    Ace PDG is committed to advancing the science of learning
                    and teaching, and records of your participation in Quizzes
                    and Mock Exams may be used for educational research. In the
                    interest of this research, you may be exposed to variations
                    in the Content Oﬀerings. Research findings will typically be
                    reported at the aggregate level. Your personal identity will
                    not be publicly disclosed in any research findings. Upon
                    registration, you will be assigned a modifiable alias; Your
                    personal information will never be tied to your performance
                    and app activity.
                  </p>
                </li>
                <li>
                  Paid Services from Ace PDG
                  <p>
                    Ace PDG oﬀers paid Services (e.g., preparation material for
                    the United States Air Force Promotion Fitness Examination)
                    for a fee. Unless otherwise stated, fees are quoted in local
                    currency. You are responsible for paying all fees charged by
                    or for Ace PDG and applicable taxes in a timely manner with
                    a payment mechanism associated with the applicable paid
                    Services. If your payment method fails or your account is
                    past due, we may collect fees using other collection
                    mechanisms. Fees may vary based on your location and other
                    factors, and Ace PDG reserves the right to change any fees
                    at any time at its sole discretion. Any change, update, or
                    modification will be eﬀective immediately upon posting
                    through the relevant Services. Refunds may be available for
                    paid Services as described in our Payments and Refund Policy
                    below.
                  </p>
                </li>
                <li>
                  Modifying or Terminating our Services
                  <p>
                    We are constantly changing and improving our Services. We
                    may add or remove functions, features, or requirements, and
                    we may suspend (to the extent allowed by applicable law) or
                    stop part of our Services altogether. Accordingly, Ace PDG
                    may terminate your use of any Service for any reason. If
                    your use of a paid Service is terminated, a refund may be
                    available under our Refund Policy. We may not be able to
                    deliver the Services to certain regions or countries for
                    various reasons, including due to applicable export control
                    requirements or internet access limitations and restrictions
                    from governments, or for commercial or reputational reasons.
                    None of Ace PDG, its contributors, sponsors, and other
                    business partners, and their employees, contractors, and
                    other agents (the "Ace PDG Parties") shall have any
                    liability to you for any such action. You can stop using our
                    Services at any time, although we'll be sorry to see you go.
                  </p>
                </li>
                <li>
                  Disclaimers
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES AND ALL
                    INCLUDED CONTENT ARE PROVIDED ON AN "AS IS" BASIS WITHOUT
                    WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE ACE
                    PDG PARTIES SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND
                    CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
                    OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE Ace PDG
                    PARTIES FURTHER DISCLAIM ANY AND ALL LIABILITY RELATED TO
                    YOUR ACCESS OR USE OF THE SERVICES OR ANY RELATED CONTENT.
                    YOU ACKNOWLEDGE AND AGREE THAT ANY ACCESS TO OR USE OF THE
                    SERVICES OR SUCH CONTENT IS AT YOUR OWN RISK.
                  </p>
                </li>
                <li>
                  Limitation of Liability
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, Ace PDG SHALL NOT BE
                    LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                    OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
                    WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
                    DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING
                    FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR
                    USE THE SERVICES; (B) ANY CONDUCT OR CONTENT OF ANY PARTY
                    OTHER THAN THE APPLICABLE Ace PDG PARTY, INCLUDING WITHOUT
                    LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT;
                    OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR
                    CONTENT OR INFORMATION. IN NO EVENT SHALL Ace PDG’S
                    AGGREGATE LIABILITY FOR ALL CLAIMS RELATED TO THE SERVICES
                    EXCEED THIRTY U.S. DOLLARS ($30.00) OR THE TOTAL AMOUNT OF
                    FEES RECEIVED BY Ace PDG FROM YOU FOR THE USE OF PAID
                    SERVICES DURING THE PAST 12 MONTHS, WHICHEVER IS GREATER.
                    YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS AND THE
                    LIMITATIONS OF LIABILITY SET FORTH IN THIS TERMS OF USE
                    REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU
                    AND Ace PDG , AND THAT THESE LIMITATIONS ARE AN ESSENTIAL
                    BASIS TO Ace PDG’S ABILITY TO MAKE SERVICES AVAILABLE TO YOU
                    ON AN ECONOMICALLY FEASIBLE BASIS.
                  </p>
                  <p>
                    YOU AGREE THAT ANY CAUSE OF ACTION RELATED TO THE SERVICES
                    MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                    ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                    BARRED.
                  </p>
                </li>
                <li>
                  Indemnification
                  <p>
                    You agree to indemnify, defend, and hold harmless Ace PDG
                    from any and all claims, liabilities, expenses, and damages
                    (to the extent attributable to you under the applicable
                    law), including reasonable attorneys' fees and costs, made
                    by any third party related to: (a) your use or attempted use
                    of the Services in violation of these Terms; (b) your
                    violation of any law or rights of any third party; or (c)
                    User Content, including without limitation any claim of
                    infringement or misappropriation of intellectual property or
                    other proprietary rights.
                  </p>
                </li>
                <li>
                  Governing Law and Venue
                  <p>
                    Except as provided below, the Services are managed by Ready
                    Military Apps LLC, which is located in Casper, Wyoming. You
                    agree that these Terms will be governed by the laws of the
                    State of Wyoming, excluding its conflicts of law provisions.
                  </p>
                  <p>
                    In the event of any dispute that is not subject to binding
                    arbitration, you and Ace PDG will submit to the personal
                    jurisdiction of and exclusive venue in the federal and state
                    courts located in and serving Wyoming as the legal forum for
                    any such dispute (except for small claims court actions
                    which may be brought in the county where you reside).
                  </p>
                  <p>
                    These Terms shall not deprive you of any mandatory consumer
                    protections under the law of the country to which we direct
                    Services to you, where you have your habitual residence.
                  </p>
                </li>
                <li>
                  Binding Arbitration and Class Action Waiver
                  <p>
                    If you live in the U.S. or another jurisdiction which allows
                    you to agree to arbitration, you and Ace PDG agree to submit
                    to binding arbitration any and all disputes, claims, or
                    controversies of any kind, whether based on statute,
                    regulation, constitution, common law, equity, or any other
                    legal basis or theory, and whether pre-existing, present, or
                    future, that arise out of or relate to our Services, these
                    Terms, and/or any other relationship or dispute between you
                    and us, including without limitation (i) the scope,
                    applicability, validity or enforceability of these Terms
                    and/or this arbitration provision, and (ii) relationships
                    with third parties who are not parties to these Terms or
                    this arbitration provision to the fullest extent permitted
                    by applicable law (each a “Claim,” and collectively the
                    “Claims”). Claims will also include disputes that arose or
                    involve facts occurring before the existence of this or any
                    prior versions of the Terms as well as Claims that may arise
                    after the termination of these Terms.
                  </p>
                  <p>
                    This arbitration agreement does not preclude you or us from
                    seeking action by federal, state, or local government
                    agencies. You and we also have the right to exercise
                    self-help remedies, such as set-oﬀ, or to bring qualifying
                    claims in small claims court so long as the matter remains
                    in such court and advances only on an individual (non-class,
                    non- representative) basis. In addition, you and we retain
                    the right to apply to any court of competent jurisdiction
                    for provisional or ancillary relief, including pre-arbitral
                    attachments or preliminary injunctions, and any such request
                    shall not be deemed incompatible with any of these Terms,
                    nor a waiver of the right to have disputes submitted to
                    arbitration as provided in this provision
                  </p>
                  <p>
                    Before filing an arbitration, you and we will try in good
                    faith to informally resolve any Claims. To start the dispute
                    process, you must send an individualized written notice
                    (“Notice of Dispute”) to acepdgapp@gmail.com that includes
                    (1) your name, phone number, username (if applicable), and
                    email address for your account (2) a description of the
                    Claim and how you’d like it resolved and (3) the name,
                    telephone number, mailing address and e-mail address of your
                    counsel, if any. If we have a Claim with you, we will send a
                    Notice of Dispute with the same information to the email
                    address for your account. Once a complete Notice of Dispute
                    has been received, the recipient has 60 days to investigate
                    the claims. If either side requests a settlement conference
                    during this period, then you and us must cooperate to
                    schedule that meeting by phone or videoconference. At the
                    option of a party, you and Ace PDG each will personally
                    participate and can each bring counsel, but the conference
                    must be individualized, even if the same firm(s) represent
                    multiple parties. For the Claims in the Notice of Dispute,
                    any statute of limitations will be tolled from the date the
                    notice is received until the later of (i) 60 days, or (ii)
                    after a timely requested settlement conference is completed
                    (“Informal Resolution Period”). An arbitration cannot be
                    filed until the Informal Resolution Period has ended, and a
                    court can enjoin the filing or prosecution of an arbitration
                    in breach of this Section.
                  </p>
                  <p>
                    <b>
                      YOU AND ACE PDG HEREBY WAIVE ANY CONSTITUTIONAL AND
                      STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT
                      OF A JUDGE OR A JURY.
                    </b>{" "}
                    You and Ace PDG are instead electing that all Claims shall
                    be resolved by arbitration under this arbitration agreement,
                    except as specified above. There is no judge or jury in
                    arbitration, and court review of an arbitration award is
                    subject to very limited review.
                  </p>
                  <p>
                    <b>
                      EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
                      INDIVIDUAL BASIS AND NOT ON A CLASS, REPRESENTATIVE, OR
                      COLLECTIVE BASIS, AND THE PARTIES HEREBY WAIVE ALL RIGHTS
                      TO HAVE ANY DISPUTE BE BROUGHT, HEARD, ADMINISTERED,
                      RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE,
                      REPRESENTATIVE, OR MASS ACTION BASIS. ONLY INDIVIDUAL
                      RELIEF IS AVAILABLE.
                    </b>{" "}
                    Subject to this arbitration provision, the arbitrator may
                    award declaratory or injunctive relief only in favor of the
                    individual party seeking relief and only to the extent
                    necessary to provide relief warranted by the party's
                    individual claim. Nothing in this paragraph is intended to,
                    nor shall it, aﬀect the terms and conditions of the
                    subsection below related to batch arbitration.
                    Notwithstanding anything to the contrary in this arbitration
                    provision, if a final decision, not subject to any further
                    appeal or recourse, determines that the limitations of this
                    subsection are invalid or unenforceable as to a particular
                    claim or request for relief (such as a request for public
                    injunctive relief), you and Ace PDG agree that that
                    particular claim or request for relief (and only that
                    particular claim or request for relief) shall be severed
                    from the arbitration and may be litigated in the state or
                    federal courts located in Wyoming. All other Claims shall be
                    arbitrated or litigated in small claims court. This
                    subsection does not prevent you or Ace PDG from
                    participating in a class-wide settlement of claims.
                  </p>
                  <p>
                    The Terms evidence a transaction involving interstate
                    commerce; and notwithstanding any other provision herein
                    with respect to the applicable substantive law, the Federal
                    Arbitration Act, 9 U.S.C. § 1 et seq., will govern the
                    interpretation and enforcement of this arbitration provision
                    and any arbitration proceedings. If Informal Dispute
                    Resolution does not resolve satisfactorily within sixty (60)
                    days after receipt of a Notice of Dispute, you and Ace PDG
                    agree that either party shall have the right to finally
                    resolve the Claim through binding arbitration. Any and all
                    Claims shall be submitted for binding arbitration in
                    accordance with the National Arbitration & Mediation ("NAM”)
                    Comprehensive Dispute Resolution Rules and Procedures (the
                    “NAM Rules”), in eﬀect at the time arbitration is initiated,
                    except as supplemented, where applicable, by the NAM Mass
                    Filing Supplemental Dispute Resolution Rules and Procedures,
                    and as modified by this arbitration agreement. NAM Rules are
                    available online at
                    https://www.namadr.com/resources/rules-fees-forms/. In the
                    event of any inconsistency between this arbitration
                    provision and the NAM Rules, such inconsistency shall be
                    resolved in favor of this provision.
                  </p>
                  <p>
                    A party who wishes to initiate arbitration must provide the
                    other party with a request for arbitration (the “Request”).
                    A Request to Ace PDG should be sent by email to
                    acepdgapp@gmail.com. A Request to you will be sent to your
                    email address or regular address associated with your
                    account. It is your responsibility to keep your contact
                    information up to date. The Request must include: (1) the
                    name, telephone number, mailing address, e-mail address of
                    the party seeking arbitration and the account username (if
                    applicable) as well as the email address associated with any
                    applicable account; (2) a statement of the legal claims
                    being asserted and the factual bases of those claims; (3) a
                    description of the remedy sought and an accurate, good-faith
                    calculation of the amount in controversy in United States
                    Dollars; (4) a statement certifying completion of the
                    informal dispute resolution process as described above; and
                    (5) evidence that the requesting party has paid any
                    necessary filing fees in connection with such arbitration.
                  </p>
                  <p>
                    If the party requesting arbitration is represented by
                    counsel, the Request shall also include counsel’s name,
                    telephone number, mailing address, and email address. Such
                    counsel must also sign the Request. By signing the Request,
                    counsel certifies to the best of counsel’s knowledge,
                    information, and belief, formed after an inquiry reasonable
                    under the circumstances, that: (1) the Request is not being
                    presented for any improper purpose, such as to harass, cause
                    unnecessary delay, or needlessly increase the cost of
                    dispute resolution; (2) the claims, defenses and other legal
                    contentions are warranted by existing law or by a
                    nonfrivolous argument for extending, modifying, or reversing
                    existing law or for establishing new law; and (3) the
                    factual and damages contentions have evidentiary support or,
                    if specifically so identified, will likely have evidentiary
                    support after a reasonable opportunity for further
                    investigation or discovery.
                  </p>
                  <p>
                    The arbitration hearing (if any) will be held by
                    videoconference, unless either party requests an in-person
                    hearing and the arbitrator agrees. Unless you and Ace PDG
                    otherwise agree, an in-person hearing will be held in the
                    county where you reside (for U.S. residents), Wyoming (for
                    non-U.S. residents), or as determined by the arbitrator (in
                    the case of batch arbitration). Your responsibility to pay
                    any NAM fees and costs will be solely as set forth in the
                    applicable NAM Rules. The parties shall bear their own
                    attorneys’ fees and costs in arbitration unless the
                    arbitrator finds that either the substance of the Claim or
                    the relief sought in the request for arbitration was
                    frivolous or was brought for an improper purpose (as
                    measured by the standards set forth in Federal Rule of Civil
                    Procedure 11(b)).
                  </p>
                  <p>
                    A single, neutral arbitrator selected in accordance with the
                    NAM Rules shall decide all Claims. If the batch arbitration
                    process is triggered, NAM will appoint the arbitrator for
                    each batch. The arbitrator shall be an active member in good
                    standing of the bar for any state in the continental United
                    States and shall be either actively engaged in the practice
                    of law for at least five years or a retired judge. The
                    arbitrator shall honor claims of privilege recognized at
                    law. The arbitrator shall have exclusive authority to
                    resolve any Claim, including, without limitation, disputes
                    regarding the interpretation or application of the
                    arbitration provision, including the enforceability,
                    revocability, scope, or validity of the arbitration
                    provision or any portion of the arbitration provision,
                    except that all Claims regarding the class action waiver
                    provision, including any claim that all or part of that
                    provision is unenforceable, illegal, void or voidable, or
                    that it has been breached, shall be decided by a court of
                    competent jurisdiction and not by an arbitrator. The
                    arbitrator shall have the authority to grant motions
                    dispositive of all or part of any Claim. The arbitrator also
                    shall enforce statutes of limitations and other time-based
                    defenses, oﬀers of judgment/compromise and fee-shifting
                    rules in the same way as a court would. The arbitrator’s
                    decision will be final and binding upon the parties and may
                    be enforced in any federal or state court that has
                    jurisdiction. You and we agree that the arbitration shall be
                    kept confidential and that the existence of the proceeding
                    and any element of it (including, without limitation, any
                    pleadings, briefs or other documents submitted or exchanged
                    and any testimony or other oral submissions and awards) will
                    not be disclosed beyond the arbitration proceedings, except
                    as may lawfully be required in judicial proceedings relating
                    to the arbitration or by applicable disclosure rules and
                    regulations of governmental agencies.
                  </p>
                  <p>
                    To increase the eﬃciency of administration and resolution of
                    arbitrations, you and us agree that in the event that there
                    are ten (10) or more individual requests for arbitration of
                    a substantially similar nature filed against Ace PDG by or
                    with the assistance of the same law firm, group of law
                    firms, or organizations, within a ninety (90) day period (or
                    as soon as possible thereafter), NAM shall (1) administer
                    the arbitration demands in batches of 100 requests per batch
                    (or, if between ten (10) and ninety-nine (99) individual
                    requests are filed, a single batch of all those requests,
                    and, to the extent there are less than 100 requests
                    remaining after the batching described above, a final batch
                    consisting of the remaining requests); (2) appoint one
                    arbitrator for each batch; and (3) provide for the
                    resolution of each batch as a single consolidated
                    arbitration with one set of filing and administrative fees
                    due per side per batch, one procedural calendar, one hearing
                    (if any) in a place to be determined by the arbitrator, and
                    one final award (“Batch Arbitration”). All parties agree
                    that requests are of a “substantially similar nature” if
                    they arise out of or relate to the same event or factual
                    scenario and raise the same or similar legal issues and seek
                    the same or similar relief. To the extent the parties
                    disagree on the application of the Batch Arbitration
                    process, the disagreeing party shall advise NAM, and NAM
                    shall appoint a sole standing arbitrator to determine the
                    applicability of the Batch Arbitration process
                    (“Administrative Arbitrator”). In an eﬀort to expedite
                    resolution of any such dispute by the Administrative
                    Arbitrator, the parties agree the Administrative Arbitrator
                    may set forth such procedures as are necessary to resolve
                    any disputes promptly. The Administrative Arbitrator’s fees
                    shall be paid by Ace PDG. You and Ace PDG agree to cooperate
                    in good faith with NAM to implement the Batch Arbitration
                    process including the payment of single filing and
                    administrative fees for batches of requests, as well as any
                    steps to minimize the time and costs of arbitration, which
                    may include: (1) the appointment of a discovery special
                    master to assist the arbitrator in the resolution of
                    discovery disputes; and (2) the adoption of an expedited
                    calendar of the arbitration proceedings. This Batch
                    Arbitration provision shall in no way be interpreted as
                    authorizing a class, collective and/or mass arbitration or
                    action of any kind, except as expressly set forth in this
                    provision.
                  </p>
                  <p>
                    You and we agree that Ace PDG retains the right to modify
                    this arbitration agreement in the future. Any such changes
                    will be posted at www.acepdg.org/terms-and-condition and you
                    should check for updates regularly. Notwithstanding any
                    provision in these Terms to the contrary, we agree that if
                    Ace PDG makes any future material change to this arbitration
                    agreement, it will notify you. Your continued use of the Ace
                    PDG website and/or Services, including the acceptance of
                    products and Services oﬀered on the website following the
                    posting of changes to this arbitration agreement,
                    constitutes your acceptance of any such changes. Except as
                    provided in the class action waiver section, a court may
                    sever any portion of this Binding Arbitration and Class
                    Action Waiver Section that it finds to be unenforceable, and
                    the remaining portions will remain valid and enforceable.
                  </p>
                </li>

                <li>
                  General Terms
                  <p>Revisions to the Terms</p>
                  <p>
                    We reserve the right to revise the Terms at our sole
                    discretion at any time. With the exception of changes to the
                    Binding Arbitration and Class Action Waiver Section, any
                    revisions to the Terms will be eﬀective immediately upon
                    posting by us. For any material changes to the Terms, we
                    will take reasonable steps to notify you of such changes,
                    via a banner on the website, email notification, another
                    method, or combination of methods. In all cases, your
                    continued use of the Services after publication of such
                    changes, with or without notification, constitutes binding
                    acceptance of the revised Terms.
                  </p>
                  <p>Severability; Waiver</p>
                  <p>
                    If it turns out that a particular provision of these Terms
                    is not enforceable, this will not aﬀect any other terms. If
                    you do not comply with these Terms, and we do not take
                    immediate action, this does not indicate that we relinquish
                    any rights that we may have (such as taking action in the
                    future).
                  </p>
                  <p>Notice for California Users</p>
                  <p>
                    Under California Civil Code Section 1789.3, California
                    website users are entitled to the following specific
                    consumer rights notice: The Complaint Assistance Unit of the
                    Division of Consumer Services of the California Department
                    of Consumer Aﬀairs may be contacted in writing at 1625 N.
                    Market Blvd., Suite S-202, Sacramento, California 95834, or
                    by telephone at (800) 952-5210.
                  </p>
                </li>
                <div id="acceptable-use-policy" />
                <li>
                  Referenced Policies
                  <ul>
                    <li>Acceptable Use Policy</li>
                    <li>Copyright and Trademark Infringement Policy</li>
                    <li>Refund Policy</li>
                    <li>Honor Code</li>
                  </ul>
                </li>
              </ol>
            </div>

            <h3>Acceptable Use Policy</h3>
            <h5>Eﬀective: November 1, 2024.</h5>

            <div>
              <p>
                Our mission is to Develop best in class software applications
                for military members to prepare for promotion exams. We believe
                strongly in preserving free speech and expression for our users.
                We also want to make sure that all of our users feel safe and
                comfortable while using our Services. We have drafted this
                policy to ensure that people understand and follow the rules
                when participating in our online community and otherwise using
                our Services.
              </p>
              <p>
                We may remove or edit inappropriate content or activity
                identified by or reported to us. We may separately suspend,
                disable, or terminate a user's access to all or part of the
                Services.
              </p>
              <ol>
                <li>
                  You are prohibited from using our Services to share content
                  that:
                  <ul>
                    <li>
                      Contains illegal content or promotes illegal activities
                      with the intent to commit such activities.
                    </li>
                    <li>
                      Contains credible threats or organizes acts of real-world
                      violence. We don’t allow content that creates a genuine
                      risk of physical injury or property damage, credibly
                      threatens people or public safety, or organizes or
                      encourages harm.
                    </li>
                    <li>
                      Contains credible threats or organizes acts of real-world
                      violence. We don’t allow content that creates a genuine
                      risk of physical injury or property damage, credibly
                      threatens people or public safety, or organizes or
                      encourages harm.
                    </li>
                    <li>
                      Harasses others. We encourage commentary about people and
                      matters of public interest, but abusive or otherwise
                      inappropriate content directed at private individuals is
                      not allowed.
                    </li>
                    <li>
                      Violates intellectual property, privacy, or other rights.
                      Do not share content that you do not have the right to
                      share, claim content that you did not create as your own,
                      or otherwise infringe or misappropriate someone else’s
                      intellectual property or other rights. Always attribute
                      materials used or quoted by you to the original copyright
                      owner.
                    </li>
                    <li>
                      Spams others. Do not share irrelevant or inappropriate
                      advertising, promotional, or solicitation content.
                    </li>
                    <li>Otherwise violates the Ace PDG Terms of Use.</li>
                  </ul>
                </li>
                <li>
                  {" "}
                  You also aren't allowed to:
                  <ul>
                    <li>
                      Do anything that violates local, state, national, or
                      international law or breaches any of your contractual
                      obligations or fiduciary duties.
                    </li>
                    <li>
                      Share your password, let anyone besides yourself access
                      your account, or do anything that might put your account
                      at risk.
                    </li>
                    <li>Attempt to access any other user's account.</li>
                    <li>
                      Reproduce, transfer, sell, resell, or otherwise misuse any
                      content from our Services, unless specifically authorized
                      to do so.
                    </li>
                    <li>
                      Access, tamper with, or use non-public areas of our
                      systems, unless specifically authorized to do so.
                    </li>
                    <li>
                      Break or circumvent our authentication or security
                      measures or otherwise test the vulnerability of our
                      systems or networks, unless specifically authorized to do
                      so.
                    </li>
                    <li>
                      Try to reverse engineer any portion of our Services.
                    </li>
                    <li>
                      Try to interfere with any user, host, or network, for
                      example by sending a virus, overloading, spamming, or
                      mail-bombing.
                    </li>
                    <li>Use our Services to distribute malware.</li>
                    <li>
                      Use our Services or any functionality of the Ace PDG
                      platform for anything other than for completing online
                      courses or for pedagogical purposes.
                    </li>
                    <li>
                      Impersonate or misrepresent your aﬃliation with any person
                      or entity.
                    </li>
                    <li>
                      Encourage or help anyone do any of the things on this
                      list.
                    </li>
                  </ul>
                </li>
                <li>
                  Without prior written consent from us, you also aren't allowed
                  to:
                  <ul>
                    <li>
                      Visit or use our Services for any form of content, data,
                      or text scraping (including but not limited to screen
                      scraping, web harvesting, or web data extracting) through
                      manual, mechanical, or automated means including by the
                      use of bots or other similar software.
                    </li>
                    <div id="copyright-trademark" />
                    <li>
                      Use any content, data, or text in any form in the Services
                      for text or data mining purposes, or develop or train any
                      application, software, code, or data models including but
                      not limited to generative artificial intelligence or other
                      artificial intelligence and machine learning models
                      irrespective of the purposes whether commercial or
                      non-commercial.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            <h3>Copyright and Trademark Policy</h3>
            <h5>Eﬀective: November 1, 2024.</h5>
            <div>
              <p>
                Ace PDG respects the intellectual property rights of our users
                and other third parties and expects our users to do the same
                when using the Services. We reserve the right to suspend,
                disable, or terminate the accounts of users who repeatedly
                infringe or are repeatedly charged with infringing the
                copyrights, trademarks, or other intellectual property rights of
                others.
              </p>
              <p>
                The Digital Millennium Copyright Act of 1998 (the "DMCA")
                provides recourse for copyright owners who believe that material
                appearing on the Internet infringes their rights under U.S.
                copyright law. If you would like to read the DMCA, please visit
                the U.S. Copyright Oﬃce website at
                http://www.copyright.gov/legislation/dmca.pdf.
              </p>
              <p>
                If you believe in good faith that materials on the Ace PDG
                platform infringe your copyright, the DMCA provides that you (or
                your agent) may send us a notice requesting that the material be
                removed or access to it blocked.
              </p>
              <p>The notice must include the following information:</p>
              <ul>
                <li>
                  the physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </li>
                <li>
                  identification of the copyrighted work claimed to have been
                  infringed (or, if multiple copyrighted works located on the
                  platform are covered by a single notification, a
                  representative list of such works);
                </li>
                <li>
                  identification of the material that is claimed to be
                  infringing or the subject of infringing activity, and
                  information reasonably suﬃcient to allow Ace PDG to locate the
                  material on the platform;
                </li>
                <li>
                  the name, address, telephone number, and email address (if
                  available) of the complaining party;
                </li>
                <li>
                  a statement that the complaining party has a good faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent, or the law; and
                </li>
                <li>
                  a statement that the information in the notification is
                  accurate and, under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ul>
              <p>
                Notices must meet the then-current statutory requirements
                imposed by the DMCA. Notices and counter-notices with respect to
                the Services can either be sent:
              </p>
              <ul>
                <li>via email: acepdgapp@gmail.com</li>
              </ul>
              <p>
                We suggest that you consult your legal advisor before filing a
                notice. Also, be aware that there can be penalties for false
                claims under the DMCA.
              </p>
              <div id="payments-and-refund" />
              <p>
                Ace PDG also respects the trademark rights of others. Accounts
                with any other content that misleads others or violates
                another's trademark may be updated, suspended, disabled, or
                terminated by Ace PDG in its sole discretion. If you are
                concerned that someone may be using your trademark without
                proper permission on our Services, email us at
                acepdgapp@gmail.com and we will review your submission. If we
                deem appropriate, we may remove the oﬀending content, warn the
                individual who posted the content, and/or temporarily or
                permanently suspend or disable the individual’s account.
              </p>
            </div>

            <h3>Payments and Refund Policy</h3>
            <h5>Eﬀective: November 1, 2024.</h5>
            <div>
              <p>
                For details on our refund and cancellation policies, please
                refer to the information below. Please note that our policies
                may diﬀer between oﬀerings, and payment options may vary. Please
                also note that we treat violations of our Terms of Use and Honor
                Code very seriously, and we have no obligation to oﬀer refunds
                to users who violate these or other Ace PDG policies, even if
                their requests are made within the designated refund period.
                Similarly, we have no obligation to oﬀer late refunds to users
                who do not receive a passing mark in a Content Oﬀering, or who
                are otherwise unsatisfied with their final grade.
              </p>
              <p>
                For more information about our refund process, including
                instructions for requesting a refund, please email
                acepdgapp@gmail.com.
              </p>
              <ol>
                <li>
                  Premium Subscription
                  <p>Refunds for Trials</p>
                  <p>
                    To avoid being charged during a free trial period, you must
                    cancel your subscription before your free trial ends. If you
                    do not cancel during the free trial, you will be billed the
                    full amount for the subscription you signed up for.
                  </p>
                  <p>Cancellation</p>
                  <p>
                    Your subscription will continue on annual basis unless and
                    until you cancel or the subscription is suspended or
                    discontinued by Ace PDG. If you cancel your subscription,
                    cancellation will be eﬀective at the end of the current
                    subscription period. You will continue to have access to
                    your subscription for the remainder of that period, but you
                    will not receive a refund.
                  </p>
                </li>
                <li>
                  Other Paid Services
                  <p>
                    Except as described in this Refund Policy or as explicitly
                    stated as part of the sign-up process for a Service, Ace PDG
                    has no obligation to provide refunds or vouchers for any
                    other Services.
                  </p>
                  <p>
                    Ace PDG reserves the right to oﬀer refunds at its
                    discretion. Please note that our policies diﬀer between
                    subscription payments and any one-time payments made on our
                    platform. Please also note that we treat violations of our
                    Terms of Use and Honor Code very seriously, and we have no
                    obligation to oﬀer refunds to users who violate these or
                    other Ace PDG policies, even if their requests are made
                    within the designated refund period. Similarly, we have no
                    obligation to oﬀer late refunds to users who do not receive
                    a passing mark in a Content Oﬀering, or who are otherwise
                    unsatisfied with their final grade.
                  </p>
                  <p>
                    For more information about our refund process, including
                    instructions for requesting a refund, please email
                    acepdgapp@gmail.com.
                  </p>
                </li>
                <li>
                  Third Party Marketplaces
                  <p>
                    Notwithstanding the foregoing, if you purchase or subscribe
                    to any paid Service, through a third party marketplace
                    (e.g., in-app purchases through the Apple App Store or
                    purchases made through certain alternative payment
                    services), the refund policy applicable to that third party
                    marketplace will apply, unless otherwise explicitly stated
                    by Ace PDG. The third party marketplace will be solely
                    responsible for making refunds under its refund policy, and
                    Ace PDG will have no refund obligations. Ace PDG disclaims
                    any responsibility or liability related to any third party
                    marketplace's refund policy or the third party's compliance
                    or noncompliance with such policy.
                  </p>
                </li>
                <li>
                  Promotional Oﬀers
                  <p>
                    We may from time to time oﬀer special promotional oﬀers or
                    discounts (“Oﬀers”). Oﬀer eligibility is determined by Ace
                    PDG at its sole discretion and we reserve the right to
                    revoke an Oﬀer in the event that we determine you are not
                    eligible. Users with an existing Oﬀer may not be eligible
                    for additional Oﬀers. We may use information such as method
                    of payment or an account email address used with a Ace PDG
                    purchase to determine Oﬀer eligibility. The eligibility
                    requirements and other limitations and conditions will be
                    disclosed when you sign up for the Oﬀer or in other
                    communications made available to you.
                  </p>
                </li>
                <li>
                  Changes to Price and Subscription Plans
                  <p>
                    We reserve the right to change our subscription plans or
                    adjust pricing for our service or any components thereof in
                    any manner and at any time as we may determine in our sole
                    and absolute discretion. Except as otherwise expressly
                    provided for in these Terms, any price changes or changes to
                    your subscription will take eﬀect following notice to you.
                  </p>
                </li>
              </ol>
              {/* <div id="honor-code" /> */}
            </div>
            {/* <h3>Honor Code</h3>
            <h5>Eﬀective: November 1, 2024.</h5>
            <div>
              <p>
                All users of learning materials hosted on the Ace PDG platform
                are expected to abide by the following standards to ensure the
                integrity of learning within Ace PDG learning experiences.
                Learners are expected to familiarize themselves and comply with
                the Terms of Use for the Ace PDG platform and any code of
                conduct, academic policy, honor code or or any institution's
                requirements associated with programs which a learner has
                enrolled onto through the Ace PDG platform has established.
              </p>
              <p>
                Academic misconduct undermines the value of Ace PDG hosted
                credentials and devalues the authentic eﬀorts of other learners.
                Learners who engage in misconduct are therefore subject to
                consequences as described below.
              </p>
              <p>
                <b>Academic misconduct</b> is defined as any activity which
                circumvents, or attempts to circumvent, the learning experience
                provided by Ace PDG through violation of course learning
                policies. The following standards provide clarification of the
                most common types of academic misconduct, but the list is not
                exhaustive. Other behavior may constitute academic misconduct
                across the Ace PDG platform.
              </p>
              <p>Definitions</p>
              <p>
                <b>Work within a Ace PDG activity</b> - all activity on the Ace
                PDG platform including logins and all learning activities is
                subject to these policies. Work includes but is not limited to
                Quizzes and Mock Exams.
              </p>
              <p>
                <b>Unauthorized</b> - activities that violate Ace PDG’s Terms of
                Use, course or platform policies, activities which are illegal,
                or those activities which violate academic standards or
                standards of integrity that a learner on the Ace PDG platform
                should be expected to know are considered to be unauthorized.
              </p>
              <p>Violations</p>
              <ol>
                <li>
                  <b>Plagiarism</b>: Plagiarism is when you copy or reproduce
                  words, ideas, or any other materials from another source
                  without giving credit to the original author. Plagiarism also
                  includes the practice of employing or allowing another person
                  to alter or revise your work, and then submitting the work as
                  your own. Learners may discuss assessments among themselves,
                  or with others, but all assessment submissions must be
                  original works done independently by the learner. This
                  includes work paraphrased, translated or otherwise modified
                  using automated means.
                </li>
                <li>
                  {" "}
                  <b>Use of Unauthorized Materials</b>: using or consulting
                  unauthorized materials (including electronic materials and
                  generative AI tools including, but not limited to, ChatGPT or
                  similar software or applications) or using unauthorized
                  equipment or devices on any work within a Ace PDG activity,
                  unless expressly permitted.
                </li>
                <li>
                  <b>Unauthorized collaboration</b>: working together with any
                  person on any work within a Ace PDG activity unless expressly
                  permitted.
                </li>
                <li>
                  <b>Contract cheating</b>: paying, trading, or otherwise
                  getting another person to create work for submission to a Ace
                  PDG activity.
                </li>
                <li>
                  <b>Impersonation</b>: completing work within any Ace PDG
                  activity (including but not limited to Quizzes, Mock Exam, and
                  Leaderboard activities) when logged in as another user or
                  having another user complete work within a Ace PDG activity
                  when logged in as you; otherwise misrepresenting the
                  authorship of work submitted on Ace PDG.
                </li>
                <li>
                  <b>Unauthorized sharing of resources</b>: making any
                  information about or solutions to, Quizzes, Mock Exams or
                  Leaderboard available to anyone else (except to the extent an
                  assessment explicitly permits sharing solutions). This
                  includes both solutions written by you, other learners, and
                  outside resources.
                </li>
                <li>
                  <b>Fraud</b>: any misrepresentation about the authorship or
                  the conditions under which work within a Ace PDG activity was
                  performed not otherwise specified or falsifying any evidence
                  in support of any mitigating circumstances claim.
                </li>
                <li>
                  <b>Other</b>: any other behavior that confers an unfair
                  advantage to you or someone else or any activity that attempts
                  to dishonestly improve your results or improve or harm the
                  results of others in the performance of work within a PDG
                  Ready activity.
                </li>
              </ol>
              <p>Consequences</p>
              <p>
                <b>No refunds</b> will be issued in the case of any corrective
                action for such violations. Honor Code violations will be
                determined at the sole discretion of Ace PDG and Ace PDG
                partners. You will be notified if a determination has been made
                that you have violated this Honor Code and you will be informed
                of the corresponding action to be taken as a result of the
                violation.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
