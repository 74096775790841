import React, { useEffect } from "react";
import { Navigation } from "./navigation";

import { useLocation } from "react-router-dom";

export const PrivacyPolicy = (props) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isMobileApp = searchParams.get("mobile") || 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {isMobileApp != 1 && <Navigation whiteBg={true} />}
      <div
        id="terms-condition-privacy"
        className={`container ${isMobileApp == 1 ? "container-mobileapp" : ""}`}
      >
        {!isMobileApp && <div className="terms-spacer" />}
        <div className="row">
          {/* <div className="left-nav">
            <div className="row">
              <div className="left-nav-title terms">
                <a
                  className="collapsed sidebar-title"
                  data-toggle="collapse"
                  data-target="#terms-sidebar"
                  aria-expanded="false"
                  aria-controls="terms-sidebar"
                  type="button"
                >
                  Terms & Condition
                </a>
              </div>
              <div id="terms-sidebar" className="collapse">
                <div>
                  <a href="#terms-of-use">Terms of Use</a>
                </div>
                <div>
                  <a href="#acceptable-use-policy">Acceptable Use Policy</a>
                </div>
                <div>
                  <a href="#copyright-trademark">
                    Copyright and Trademark Policy
                  </a>
                </div>
                <div>
                  <a href="#payments-and-refund">Payments and Refund Policy</a>
                </div>
                <div>
                  <a href="#honor-code">Honor Code</a>
                </div>
              </div>
              <div className="left-nav-title">
                <a href="#privacy-notice" className="sidebar-title">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 mb-4">
            {/* <h3 id="terms-of-use">Terms of Use </h3>
            <h5>Eﬀective: November 1, 2024.</h5>

            <div>
              <p>Thank you for using Ace PDG!</p>
              <p>
                Ace PDG oﬀers products and services provided by Ace Military
                Apps LLC, a limited liability company incorporated in the State
                of Wyoming (collectively, "Ace Military Apps," “Ace PDG,” "us,"
                "we," or "our"). These Terms of Use ("Terms") govern your use of
                our website, apps, and other products and services ("Services").
                As some of our Services may be software that is downloaded to
                your computer, phone, tablet, or other device, you agree that we
                may automatically update this software, and that these Terms
                will apply to such updates. Please read these Terms carefully,
                and contact us if you have any questions, requests for
                information, or complaints. By clicking “I accept” (or for those
                who have not yet created an account, by using our Services), you
                agree to be bound by these Terms, including the policies
                referenced in these Terms. By using our Services, you agree to
                be bound by these Terms, including the policies referenced in
                these Terms.
              </p>
              <p>
                <b>
                  THESE TERMS INCLUDE AN ARBITRATION AGREEMENT, JURY TRIAL
                  WAIVER AND CLASS ACTION WAIVER THAT APPLY TO ALL CLAIMS
                  BROUGHT AGAINST Ace PDG . PLEASE READ THEM CAREFULLY AS THEY
                  AFFECT YOUR LEGAL RIGHTS: (1) YOU WILL ONLY BE PERMITTED TO
                  PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF AGAINST US ON AN
                  INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                  CLASS OR REPRESENTATIVE ACTION OR PROCEEDING AND YOU WAIVE
                  YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
                  CLASS-WIDE ARBITRATION; AND (2) YOU ARE WAIVING YOUR RIGHT TO
                  PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF IN A COURT OF LAW
                  AND TO HAVE A JURY TRIAL. THE TERMS OF THE ARBITRATION
                  AGREEMENT SHALL NOT APPLY IF UNENFORCEABLE UNDER THE LAWS OF
                  THE COUNTRY IN WHICH YOU RESIDE.
                </b>
              </p>
              <ol>
                <li>
                  Using Ace PDG
                  <p>Who May Use Our Services</p>
                  <p>
                    <b>
                      Any use or access by anyone under the age of 13 is
                      strictly prohibited.
                    </b>
                  </p>
                  <p>Additionally, you may use our Services only if you:</p>
                  <ul>
                    <li>can form a binding contract with Ace PDG;</li>
                    <li>
                      comply with these Terms, all applicable laws, and our
                      policies (including the Acceptable Use Policy, Honor Code,
                      rank-specific eligibility requirements, and any other
                      policies that may be applicable from time to time
                      (“Policies”)); and
                    </li>
                    <li>
                      are over the age at which you can provide consent to data
                      processing under the laws of your country. Certain regions
                      and Content Oﬀerings may have additional requirements
                      and/or diﬀerent age restrictions.
                    </li>
                  </ul>
                  <p>
                    Any violation of our Terms, applicable laws, or Policies may
                    result in your access to all or part of the Services being
                    suspended, disabled, or terminated.
                  </p>
                  <p>
                    When you create your Ace PDG account, and when you
                    subsequently use certain features, you must provide us with
                    accurate and complete information, and you agree to update
                    your information to keep it accurate and complete.
                  </p>
                  <p>Our License to You</p>
                  <p>
                    Subject to these Terms and our Policies, we grant you a
                    limited, personal, non-exclusive, non-transferable, and
                    revocable license right to use our Services. The rights
                    granted herein are only for your personal, non-commercial
                    use, unless you obtain our written permission otherwise. You
                    also agree that you will create, access, and/or use only one
                    user account, unless expressly permitted by Ace PDG, and you
                    will not share access to your account or access information
                    for your account with any third party. Using our Services
                    does not give you ownership of or any intellectual property
                    rights in our Services or the content you access.
                  </p>
                  <p>Commercial Use</p>
                  <p>
                    Any use of our Services for commercial purposes is strictly
                    prohibited. Any commercial use must be subject to a separate
                    agreement with Ace Military Apps, Inc. If you are an
                    organization already using our Services, separate terms
                    apply.
                  </p>
                </li>
                <li>
                  Content Oﬀerings
                  <p>Changes to Content Oﬀerings</p>
                  <p>
                    Ace PDG oﬀers Study flash cards and outlines, Quizzes, Mock
                    Exams and general content ("Content Oﬀerings") from public
                    sources published by the United States Air Force. While we
                    seek to provide world-class Content Oﬀerings, unexpected
                    events do occur. Ace PDG reserves the right to cancel,
                    interrupt, reschedule, or modify any Content Oﬀerings, or
                    change the point value or weight of any assignment, quiz, or
                    other assessment. Content Oﬀerings are subject to the
                    Disclaimers and Limitation of Liability sections below.
                  </p>
                  <p>No Academic Credit</p>
                  <p>
                    Ace PDG does not grant academic credit for the completion of
                    Content Oﬀerings. Unless otherwise explicitly indicated by a
                    credit-granting institution, participation in or completion
                    of Content Oﬀerings does not confer any academic credit.
                    Even if credit is awarded by one institution, there is no
                    presumption that other institutions will accept that credit.
                    Ace PDG has no obligation to have Content Oﬀerings
                    recognized by any educational institution or accreditation
                    organization.
                  </p>
                </li>
                <li>
                  Your Content
                  <p>User Content</p>
                  <p>
                    The Services enable you to share your content, such as Mock
                    Exam results ("User Content"), with Ace PDG and/or other
                    users. You retain all intellectual property rights in, and
                    are responsible for, the User Content you create and share.
                    User Content does not include course content or other
                    materials made available on or placed on the Ace PDG
                    platform.
                  </p>
                  <p>How Ace PDG May Use User Content</p>
                  <p>
                    To the extent that you provide User Content, you grant Ace
                    PDG a fully transferable, royalty-free, perpetual,
                    sublicensable, non-exclusive, worldwide license to copy,
                    distribute, modify, create derivative works based on,
                    publicly perform, publicly display, and otherwise use the
                    User Content. We reserve the right to remove or modify User
                    Content for any reason, including User Content that we
                    believe violates these Terms or other policies including our
                    Acceptable Use Policy and Code of Conduct.
                  </p>
                  <p>Feedback</p>
                  <p>
                    We welcome your suggestions, ideas, comments, and other
                    feedback regarding the Services ("Feedback"). By submitting
                    any Feedback, you grant us the right to use the Feedback
                    without any restriction or any compensation to you. By
                    accepting your Feedback, Ace PDG does not waive any rights
                    to use similar or related Feedback previously known to Ace
                    PDG, developed by our founders, contractors, or obtained
                    from other sources.
                  </p>
                </li>
                <li>
                  Security
                  <p>
                    We care about the security of our users. While we work to
                    protect the security of your account and related
                    information, Ace PDG cannot guarantee that unauthorized
                    third parties will not be able to defeat our security
                    measures. Please notify us immediately of any compromise or
                    unauthorized use of your account by emailing
                    acepdgapp@gmail.com.
                  </p>
                </li>
                <li>
                  Copyright and Trademark
                  <p>
                    Ace PDG respects the intellectual property rights of our
                    users, and other third parties and expects our users to do
                    the same when using the Services. We have adopted and
                    implemented the Ace PDG Copyright and Trademark Policy below
                    in accordance with applicable law, including the Digital
                    Millennium Copyright Act.
                  </p>
                </li>

                <li>
                  {" "}
                  Education Research
                  <p>
                    Ace PDG is committed to advancing the science of learning
                    and teaching, and records of your participation in Quizzes
                    and Mock Exams may be used for educational research. In the
                    interest of this research, you may be exposed to variations
                    in the Content Oﬀerings. Research findings will typically be
                    reported at the aggregate level. Your personal identity will
                    not be publicly disclosed in any research findings. Upon
                    registration, you will be assigned a modifiable alias; Your
                    personal information will never be tied to your performance
                    and app activity.
                  </p>
                </li>
                <li>
                  Paid Services from Ace PDG
                  <p>
                    Ace PDG oﬀers paid Services (e.g., preparation material for
                    the United States Air Force Promotion Fitness Examination)
                    for a fee. Unless otherwise stated, fees are quoted in local
                    currency. You are responsible for paying all fees charged by
                    or for Ace PDG and applicable taxes in a timely manner with
                    a payment mechanism associated with the applicable paid
                    Services. If your payment method fails or your account is
                    past due, we may collect fees using other collection
                    mechanisms. Fees may vary based on your location and other
                    factors, and Ace PDG reserves the right to change any fees
                    at any time at its sole discretion. Any change, update, or
                    modification will be eﬀective immediately upon posting
                    through the relevant Services. Refunds may be available for
                    paid Services as described in our Payments and Refund Policy
                    below.
                  </p>
                </li>
                <li>
                  Modifying or Terminating our Services
                  <p>
                    We are constantly changing and improving our Services. We
                    may add or remove functions, features, or requirements, and
                    we may suspend (to the extent allowed by applicable law) or
                    stop part of our Services altogether. Accordingly, Ace PDG
                    may terminate your use of any Service for any reason. If
                    your use of a paid Service is terminated, a refund may be
                    available under our Refund Policy. We may not be able to
                    deliver the Services to certain regions or countries for
                    various reasons, including due to applicable export control
                    requirements or internet access limitations and restrictions
                    from governments, or for commercial or reputational reasons.
                    None of Ace PDG, its contributors, sponsors, and other
                    business partners, and their employees, contractors, and
                    other agents (the "Ace PDG Parties") shall have any
                    liability to you for any such action. You can stop using our
                    Services at any time, although we'll be sorry to see you go.
                  </p>
                </li>
                <li>
                  Disclaimers
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES AND ALL
                    INCLUDED CONTENT ARE PROVIDED ON AN "AS IS" BASIS WITHOUT
                    WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE ACE
                    PDG PARTIES SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND
                    CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
                    OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE Ace PDG
                    PARTIES FURTHER DISCLAIM ANY AND ALL LIABILITY RELATED TO
                    YOUR ACCESS OR USE OF THE SERVICES OR ANY RELATED CONTENT.
                    YOU ACKNOWLEDGE AND AGREE THAT ANY ACCESS TO OR USE OF THE
                    SERVICES OR SUCH CONTENT IS AT YOUR OWN RISK.
                  </p>
                </li>
                <li>
                  Limitation of Liability
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, Ace PDG SHALL NOT BE
                    LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                    OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
                    WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
                    DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING
                    FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR
                    USE THE SERVICES; (B) ANY CONDUCT OR CONTENT OF ANY PARTY
                    OTHER THAN THE APPLICABLE Ace PDG PARTY, INCLUDING WITHOUT
                    LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT;
                    OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR
                    CONTENT OR INFORMATION. IN NO EVENT SHALL Ace PDG’S
                    AGGREGATE LIABILITY FOR ALL CLAIMS RELATED TO THE SERVICES
                    EXCEED THIRTY U.S. DOLLARS ($30.00) OR THE TOTAL AMOUNT OF
                    FEES RECEIVED BY Ace PDG FROM YOU FOR THE USE OF PAID
                    SERVICES DURING THE PAST 12 MONTHS, WHICHEVER IS GREATER.
                    YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS AND THE
                    LIMITATIONS OF LIABILITY SET FORTH IN THIS TERMS OF USE
                    REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU
                    AND Ace PDG , AND THAT THESE LIMITATIONS ARE AN ESSENTIAL
                    BASIS TO Ace PDG’S ABILITY TO MAKE SERVICES AVAILABLE TO YOU
                    ON AN ECONOMICALLY FEASIBLE BASIS.
                  </p>
                  <p>
                    YOU AGREE THAT ANY CAUSE OF ACTION RELATED TO THE SERVICES
                    MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                    ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                    BARRED.
                  </p>
                </li>
                <li>
                  Indemnification
                  <p>
                    You agree to indemnify, defend, and hold harmless Ace PDG
                    from any and all claims, liabilities, expenses, and damages
                    (to the extent attributable to you under the applicable
                    law), including reasonable attorneys' fees and costs, made
                    by any third party related to: (a) your use or attempted use
                    of the Services in violation of these Terms; (b) your
                    violation of any law or rights of any third party; or (c)
                    User Content, including without limitation any claim of
                    infringement or misappropriation of intellectual property or
                    other proprietary rights.
                  </p>
                </li>
                <li>
                  Governing Law and Venue
                  <p>
                    Except as provided below, the Services are managed by Ready
                    Military Apps LLC, which is located in Casper, Wyoming. You
                    agree that these Terms will be governed by the laws of the
                    State of Wyoming, excluding its conflicts of law provisions.
                  </p>
                  <p>
                    In the event of any dispute that is not subject to binding
                    arbitration, you and Ace PDG will submit to the personal
                    jurisdiction of and exclusive venue in the federal and state
                    courts located in and serving Wyoming as the legal forum for
                    any such dispute (except for small claims court actions
                    which may be brought in the county where you reside).
                  </p>
                  <p>
                    These Terms shall not deprive you of any mandatory consumer
                    protections under the law of the country to which we direct
                    Services to you, where you have your habitual residence.
                  </p>
                </li>
                <li>
                  Binding Arbitration and Class Action Waiver
                  <p>
                    If you live in the U.S. or another jurisdiction which allows
                    you to agree to arbitration, you and Ace PDG agree to submit
                    to binding arbitration any and all disputes, claims, or
                    controversies of any kind, whether based on statute,
                    regulation, constitution, common law, equity, or any other
                    legal basis or theory, and whether pre-existing, present, or
                    future, that arise out of or relate to our Services, these
                    Terms, and/or any other relationship or dispute between you
                    and us, including without limitation (i) the scope,
                    applicability, validity or enforceability of these Terms
                    and/or this arbitration provision, and (ii) relationships
                    with third parties who are not parties to these Terms or
                    this arbitration provision to the fullest extent permitted
                    by applicable law (each a “Claim,” and collectively the
                    “Claims”). Claims will also include disputes that arose or
                    involve facts occurring before the existence of this or any
                    prior versions of the Terms as well as Claims that may arise
                    after the termination of these Terms.
                  </p>
                  <p>
                    This arbitration agreement does not preclude you or us from
                    seeking action by federal, state, or local government
                    agencies. You and we also have the right to exercise
                    self-help remedies, such as set-oﬀ, or to bring qualifying
                    claims in small claims court so long as the matter remains
                    in such court and advances only on an individual (non-class,
                    non- representative) basis. In addition, you and we retain
                    the right to apply to any court of competent jurisdiction
                    for provisional or ancillary relief, including pre-arbitral
                    attachments or preliminary injunctions, and any such request
                    shall not be deemed incompatible with any of these Terms,
                    nor a waiver of the right to have disputes submitted to
                    arbitration as provided in this provision
                  </p>
                  <p>
                    Before filing an arbitration, you and we will try in good
                    faith to informally resolve any Claims. To start the dispute
                    process, you must send an individualized written notice
                    (“Notice of Dispute”) to acepdgapp@gmail.com that includes
                    (1) your name, phone number, username (if applicable), and
                    email address for your account (2) a description of the
                    Claim and how you’d like it resolved and (3) the name,
                    telephone number, mailing address and e-mail address of your
                    counsel, if any. If we have a Claim with you, we will send a
                    Notice of Dispute with the same information to the email
                    address for your account. Once a complete Notice of Dispute
                    has been received, the recipient has 60 days to investigate
                    the claims. If either side requests a settlement conference
                    during this period, then you and us must cooperate to
                    schedule that meeting by phone or videoconference. At the
                    option of a party, you and Ace PDG each will personally
                    participate and can each bring counsel, but the conference
                    must be individualized, even if the same firm(s) represent
                    multiple parties. For the Claims in the Notice of Dispute,
                    any statute of limitations will be tolled from the date the
                    notice is received until the later of (i) 60 days, or (ii)
                    after a timely requested settlement conference is completed
                    (“Informal Resolution Period”). An arbitration cannot be
                    filed until the Informal Resolution Period has ended, and a
                    court can enjoin the filing or prosecution of an arbitration
                    in breach of this Section.
                  </p>
                  <p>
                    <b>
                      YOU AND Ace PDG HEREBY WAIVE ANY CONSTITUTIONAL AND
                      STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT
                      OF A JUDGE OR A JURY.
                    </b>{" "}
                    You and Ace PDG are instead electing that all Claims shall
                    be resolved by arbitration under this arbitration agreement,
                    except as specified above. There is no judge or jury in
                    arbitration, and court review of an arbitration award is
                    subject to very limited review.
                  </p>
                  <p>
                    <b>
                      EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
                      INDIVIDUAL BASIS AND NOT ON A CLASS, REPRESENTATIVE, OR
                      COLLECTIVE BASIS, AND THE PARTIES HEREBY WAIVE ALL RIGHTS
                      TO HAVE ANY DISPUTE BE BROUGHT, HEARD, ADMINISTERED,
                      RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE,
                      REPRESENTATIVE, OR MASS ACTION BASIS. ONLY INDIVIDUAL
                      RELIEF IS AVAILABLE.
                    </b>{" "}
                    Subject to this arbitration provision, the arbitrator may
                    award declaratory or injunctive relief only in favor of the
                    individual party seeking relief and only to the extent
                    necessary to provide relief warranted by the party's
                    individual claim. Nothing in this paragraph is intended to,
                    nor shall it, aﬀect the terms and conditions of the
                    subsection below related to batch arbitration.
                    Notwithstanding anything to the contrary in this arbitration
                    provision, if a final decision, not subject to any further
                    appeal or recourse, determines that the limitations of this
                    subsection are invalid or unenforceable as to a particular
                    claim or request for relief (such as a request for public
                    injunctive relief), you and Ace PDG agree that that
                    particular claim or request for relief (and only that
                    particular claim or request for relief) shall be severed
                    from the arbitration and may be litigated in the state or
                    federal courts located in Wyoming. All other Claims shall be
                    arbitrated or litigated in small claims court. This
                    subsection does not prevent you or Ace PDG from
                    participating in a class-wide settlement of claims.
                  </p>
                  <p>
                    The Terms evidence a transaction involving interstate
                    commerce; and notwithstanding any other provision herein
                    with respect to the applicable substantive law, the Federal
                    Arbitration Act, 9 U.S.C. § 1 et seq., will govern the
                    interpretation and enforcement of this arbitration provision
                    and any arbitration proceedings. If Informal Dispute
                    Resolution does not resolve satisfactorily within sixty (60)
                    days after receipt of a Notice of Dispute, you and Ace PDG
                    agree that either party shall have the right to finally
                    resolve the Claim through binding arbitration. Any and all
                    Claims shall be submitted for binding arbitration in
                    accordance with the National Arbitration & Mediation ("NAM”)
                    Comprehensive Dispute Resolution Rules and Procedures (the
                    “NAM Rules”), in eﬀect at the time arbitration is initiated,
                    except as supplemented, where applicable, by the NAM Mass
                    Filing Supplemental Dispute Resolution Rules and Procedures,
                    and as modified by this arbitration agreement. NAM Rules are
                    available online at
                    https://www.namadr.com/resources/rules-fees-forms/. In the
                    event of any inconsistency between this arbitration
                    provision and the NAM Rules, such inconsistency shall be
                    resolved in favor of this provision.
                  </p>
                  <p>
                    A party who wishes to initiate arbitration must provide the
                    other party with a request for arbitration (the “Request”).
                    A Request to Ace PDG should be sent by email to
                    acepdgapp@gmail.com. A Request to you will be sent to your
                    email address or regular address associated with your
                    account. It is your responsibility to keep your contact
                    information up to date. The Request must include: (1) the
                    name, telephone number, mailing address, e-mail address of
                    the party seeking arbitration and the account username (if
                    applicable) as well as the email address associated with any
                    applicable account; (2) a statement of the legal claims
                    being asserted and the factual bases of those claims; (3) a
                    description of the remedy sought and an accurate, good-faith
                    calculation of the amount in controversy in United States
                    Dollars; (4) a statement certifying completion of the
                    informal dispute resolution process as described above; and
                    (5) evidence that the requesting party has paid any
                    necessary filing fees in connection with such arbitration.
                  </p>
                  <p>
                    If the party requesting arbitration is represented by
                    counsel, the Request shall also include counsel’s name,
                    telephone number, mailing address, and email address. Such
                    counsel must also sign the Request. By signing the Request,
                    counsel certifies to the best of counsel’s knowledge,
                    information, and belief, formed after an inquiry reasonable
                    under the circumstances, that: (1) the Request is not being
                    presented for any improper purpose, such as to harass, cause
                    unnecessary delay, or needlessly increase the cost of
                    dispute resolution; (2) the claims, defenses and other legal
                    contentions are warranted by existing law or by a
                    nonfrivolous argument for extending, modifying, or reversing
                    existing law or for establishing new law; and (3) the
                    factual and damages contentions have evidentiary support or,
                    if specifically so identified, will likely have evidentiary
                    support after a reasonable opportunity for further
                    investigation or discovery.
                  </p>
                  <p>
                    The arbitration hearing (if any) will be held by
                    videoconference, unless either party requests an in-person
                    hearing and the arbitrator agrees. Unless you and Ace PDG
                    otherwise agree, an in-person hearing will be held in the
                    county where you reside (for U.S. residents), Wyoming (for
                    non-U.S. residents), or as determined by the arbitrator (in
                    the case of batch arbitration). Your responsibility to pay
                    any NAM fees and costs will be solely as set forth in the
                    applicable NAM Rules. The parties shall bear their own
                    attorneys’ fees and costs in arbitration unless the
                    arbitrator finds that either the substance of the Claim or
                    the relief sought in the request for arbitration was
                    frivolous or was brought for an improper purpose (as
                    measured by the standards set forth in Federal Rule of Civil
                    Procedure 11(b)).
                  </p>
                  <p>
                    A single, neutral arbitrator selected in accordance with the
                    NAM Rules shall decide all Claims. If the batch arbitration
                    process is triggered, NAM will appoint the arbitrator for
                    each batch. The arbitrator shall be an active member in good
                    standing of the bar for any state in the continental United
                    States and shall be either actively engaged in the practice
                    of law for at least five years or a retired judge. The
                    arbitrator shall honor claims of privilege recognized at
                    law. The arbitrator shall have exclusive authority to
                    resolve any Claim, including, without limitation, disputes
                    regarding the interpretation or application of the
                    arbitration provision, including the enforceability,
                    revocability, scope, or validity of the arbitration
                    provision or any portion of the arbitration provision,
                    except that all Claims regarding the class action waiver
                    provision, including any claim that all or part of that
                    provision is unenforceable, illegal, void or voidable, or
                    that it has been breached, shall be decided by a court of
                    competent jurisdiction and not by an arbitrator. The
                    arbitrator shall have the authority to grant motions
                    dispositive of all or part of any Claim. The arbitrator also
                    shall enforce statutes of limitations and other time-based
                    defenses, oﬀers of judgment/compromise and fee-shifting
                    rules in the same way as a court would. The arbitrator’s
                    decision will be final and binding upon the parties and may
                    be enforced in any federal or state court that has
                    jurisdiction. You and we agree that the arbitration shall be
                    kept confidential and that the existence of the proceeding
                    and any element of it (including, without limitation, any
                    pleadings, briefs or other documents submitted or exchanged
                    and any testimony or other oral submissions and awards) will
                    not be disclosed beyond the arbitration proceedings, except
                    as may lawfully be required in judicial proceedings relating
                    to the arbitration or by applicable disclosure rules and
                    regulations of governmental agencies.
                  </p>
                  <p>
                    To increase the eﬃciency of administration and resolution of
                    arbitrations, you and us agree that in the event that there
                    are ten (10) or more individual requests for arbitration of
                    a substantially similar nature filed against Ace PDG by or
                    with the assistance of the same law firm, group of law
                    firms, or organizations, within a ninety (90) day period (or
                    as soon as possible thereafter), NAM shall (1) administer
                    the arbitration demands in batches of 100 requests per batch
                    (or, if between ten (10) and ninety-nine (99) individual
                    requests are filed, a single batch of all those requests,
                    and, to the extent there are less than 100 requests
                    remaining after the batching described above, a final batch
                    consisting of the remaining requests); (2) appoint one
                    arbitrator for each batch; and (3) provide for the
                    resolution of each batch as a single consolidated
                    arbitration with one set of filing and administrative fees
                    due per side per batch, one procedural calendar, one hearing
                    (if any) in a place to be determined by the arbitrator, and
                    one final award (“Batch Arbitration”). All parties agree
                    that requests are of a “substantially similar nature” if
                    they arise out of or relate to the same event or factual
                    scenario and raise the same or similar legal issues and seek
                    the same or similar relief. To the extent the parties
                    disagree on the application of the Batch Arbitration
                    process, the disagreeing party shall advise NAM, and NAM
                    shall appoint a sole standing arbitrator to determine the
                    applicability of the Batch Arbitration process
                    (“Administrative Arbitrator”). In an eﬀort to expedite
                    resolution of any such dispute by the Administrative
                    Arbitrator, the parties agree the Administrative Arbitrator
                    may set forth such procedures as are necessary to resolve
                    any disputes promptly. The Administrative Arbitrator’s fees
                    shall be paid by Ace PDG. You and Ace PDG agree to cooperate
                    in good faith with NAM to implement the Batch Arbitration
                    process including the payment of single filing and
                    administrative fees for batches of requests, as well as any
                    steps to minimize the time and costs of arbitration, which
                    may include: (1) the appointment of a discovery special
                    master to assist the arbitrator in the resolution of
                    discovery disputes; and (2) the adoption of an expedited
                    calendar of the arbitration proceedings. This Batch
                    Arbitration provision shall in no way be interpreted as
                    authorizing a class, collective and/or mass arbitration or
                    action of any kind, except as expressly set forth in this
                    provision.
                  </p>
                  <p>
                    You and we agree that Ace PDG retains the right to modify
                    this arbitration agreement in the future. Any such changes
                    will be posted at www.acepdg.org/terms-condition-privacy and
                    you should check for updates regularly. Notwithstanding any
                    provision in these Terms to the contrary, we agree that if
                    Ace PDG makes any future material change to this arbitration
                    agreement, it will notify you. Your continued use of the Ace
                    PDG website and/or Services, including the acceptance of
                    products and Services oﬀered on the website following the
                    posting of changes to this arbitration agreement,
                    constitutes your acceptance of any such changes. Except as
                    provided in the class action waiver section, a court may
                    sever any portion of this Binding Arbitration and Class
                    Action Waiver Section that it finds to be unenforceable, and
                    the remaining portions will remain valid and enforceable.
                  </p>
                </li>

                <li>
                  General Terms
                  <p>Revisions to the Terms</p>
                  <p>
                    We reserve the right to revise the Terms at our sole
                    discretion at any time. With the exception of changes to the
                    Binding Arbitration and Class Action Waiver Section, any
                    revisions to the Terms will be eﬀective immediately upon
                    posting by us. For any material changes to the Terms, we
                    will take reasonable steps to notify you of such changes,
                    via a banner on the website, email notification, another
                    method, or combination of methods. In all cases, your
                    continued use of the Services after publication of such
                    changes, with or without notification, constitutes binding
                    acceptance of the revised Terms.
                  </p>
                  <p>Severability; Waiver</p>
                  <p>
                    If it turns out that a particular provision of these Terms
                    is not enforceable, this will not aﬀect any other terms. If
                    you do not comply with these Terms, and we do not take
                    immediate action, this does not indicate that we relinquish
                    any rights that we may have (such as taking action in the
                    future).
                  </p>
                  <p>Notice for California Users</p>
                  <p>
                    Under California Civil Code Section 1789.3, California
                    website users are entitled to the following specific
                    consumer rights notice: The Complaint Assistance Unit of the
                    Division of Consumer Services of the California Department
                    of Consumer Aﬀairs may be contacted in writing at 1625 N.
                    Market Blvd., Suite S-202, Sacramento, California 95834, or
                    by telephone at (800) 952-5210.
                  </p>
                </li>
                <li>
                  Referenced Policies
                  <ul>
                    <li>Acceptable Use Policy</li>
                    <li>Copyright and Trademark Infringement Policy</li>
                    <li>Refund Policy</li>
                    <li>Honor Code</li>
                  </ul>
                </li>
              </ol>
            </div>

            <h3 id="acceptable-use-policy">Acceptable Use Policy</h3>
            <h5>Eﬀective: November 1, 2024.</h5>

            <div>
              <p>
                Our mission is to Develop best in class software applications
                for military members to prepare for promotion exams. We believe
                strongly in preserving free speech and expression for our users.
                We also want to make sure that all of our users feel safe and
                comfortable while using our Services. We have drafted this
                policy to ensure that people understand and follow the rules
                when participating in our online community and otherwise using
                our Services.
              </p>
              <p>
                We may remove or edit inappropriate content or activity
                identified by or reported to us. We may separately suspend,
                disable, or terminate a user's access to all or part of the
                Services.
              </p>
              <ol>
                <li>
                  You are prohibited from using our Services to share content
                  that:
                  <ul>
                    <li>
                      Contains illegal content or promotes illegal activities
                      with the intent to commit such activities.
                    </li>
                    <li>
                      Contains credible threats or organizes acts of real-world
                      violence. We don’t allow content that creates a genuine
                      risk of physical injury or property damage, credibly
                      threatens people or public safety, or organizes or
                      encourages harm.
                    </li>
                    <li>
                      Contains credible threats or organizes acts of real-world
                      violence. We don’t allow content that creates a genuine
                      risk of physical injury or property damage, credibly
                      threatens people or public safety, or organizes or
                      encourages harm.
                    </li>
                    <li>
                      Harasses others. We encourage commentary about people and
                      matters of public interest, but abusive or otherwise
                      inappropriate content directed at private individuals is
                      not allowed.
                    </li>
                    <li>
                      Violates intellectual property, privacy, or other rights.
                      Do not share content that you do not have the right to
                      share, claim content that you did not create as your own,
                      or otherwise infringe or misappropriate someone else’s
                      intellectual property or other rights. Always attribute
                      materials used or quoted by you to the original copyright
                      owner.
                    </li>
                    <li>
                      Spams others. Do not share irrelevant or inappropriate
                      advertising, promotional, or solicitation content.
                    </li>
                    <li>Otherwise violates the Ace PDG Terms of Use.</li>
                  </ul>
                </li>
                <li>
                  {" "}
                  You also aren't allowed to:
                  <ul>
                    <li>
                      Do anything that violates local, state, national, or
                      international law or breaches any of your contractual
                      obligations or fiduciary duties.
                    </li>
                    <li>
                      Share your password, let anyone besides yourself access
                      your account, or do anything that might put your account
                      at risk.
                    </li>
                    <li>Attempt to access any other user's account.</li>
                    <li>
                      Reproduce, transfer, sell, resell, or otherwise misuse any
                      content from our Services, unless specifically authorized
                      to do so.
                    </li>
                    <li>
                      Access, tamper with, or use non-public areas of our
                      systems, unless specifically authorized to do so.
                    </li>
                    <li>
                      Break or circumvent our authentication or security
                      measures or otherwise test the vulnerability of our
                      systems or networks, unless specifically authorized to do
                      so.
                    </li>
                    <li>
                      Try to reverse engineer any portion of our Services.
                    </li>
                    <li>
                      Try to interfere with any user, host, or network, for
                      example by sending a virus, overloading, spamming, or
                      mail-bombing.
                    </li>
                    <li>Use our Services to distribute malware.</li>
                    <li>
                      Use our Services or any functionality of the Ace PDG
                      platform for anything other than for completing online
                      courses or for pedagogical purposes.
                    </li>
                    <li>
                      Impersonate or misrepresent your aﬃliation with any person
                      or entity.
                    </li>
                    <li>
                      Encourage or help anyone do any of the things on this
                      list.
                    </li>
                  </ul>
                </li>
                <li>
                  Without prior written consent from us, you also aren't allowed
                  to:
                  <ul>
                    <li>
                      Visit or use our Services for any form of content, data,
                      or text scraping (including but not limited to screen
                      scraping, web harvesting, or web data extracting) through
                      manual, mechanical, or automated means including by the
                      use of bots or other similar software.
                    </li>
                    <li>
                      Use any content, data, or text in any form in the Services
                      for text or data mining purposes, or develop or train any
                      application, software, code, or data models including but
                      not limited to generative artificial intelligence or other
                      artificial intelligence and machine learning models
                      irrespective of the purposes whether commercial or
                      non-commercial.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
 */}

            <h3 id="privacy-notice">Privacy Notice </h3>
            <h5>Eﬀective: November 1, 2024.</h5>
            <div>
              {/* KEY INFORMATION */}
              <p>Key Information</p>

              <ul>
                <li>
                  Ace Military Apps LLC is the data controller of the Personal
                  Data we collect about you (i.e., the entity that determines
                  the means and purposes of collecting, using, and disclosing
                  the Personal Data (as defined below).
                </li>
                <li>
                  We collect the Personal Data set out in the "What Personal
                  Data We Collect" section of this Privacy Notice below,
                  including account registration details such as name and email,
                  details of Content Oﬀerings you undertake, survey information
                  (where you provide it), identity verification data, and
                  information about your use of our site and Services.
                </li>

                <li>
                  We use your Personal Data for the purposes set out in the "How
                  We Use Personal Data" section of this Privacy Notice below,
                  including providing our site and Services to you, ensuring the
                  security and performance of our site, conducting research
                  relating to Content Oﬀerings, sharing information with our
                  Content Providers and our suppliers, direct marketing, and
                  performing statistical analysis of the use of our site and
                  Services.
                </li>
                <li>
                  You have a number of rights that you can exercise in relation
                  to our use of your Personal Data, as set out in the "Your
                  Rights" section of this Privacy Notice below.
                </li>
              </ul>
              <p>Purpose and who we are</p>
              <p>
                The purpose of this Privacy Notice is to describe how Ace PDG,
                Inc., our subsidiaries, and our international branches, ("Ace
                PDG ," "us," "we," or "our") collects, uses, and shares
                information about you through our online interfaces (e.g.,
                websites and mobile applications) owned and controlled by us,
                including but not limited to acepdg.org and acemilitaryapps.com
                (collectively referred to herein as the "Site"). Please read
                this Privacy Notice carefully to understand what we do. If you
                do not understand any aspects of our Privacy Notice, please feel
                free to contact us at{" "}
                <a href="mailto:acepdgapp@gmail.com">acepdgapp@gmail.com</a>.
                Your use of our Site is also governed by our Terms of Use. Terms
                used but not defined in this Privacy Notice can be found in our
                Terms of Use. Ace Military Apps LLC is a Wyoming corporation
                with a principal place of business at 312 W 2nd Street, Unit
                #A2833, Casper, WY 82601.
              </p>
              <p>What Information this Privacy Notice Covers</p>
              <p>
                This Privacy Notice covers information we collect from you
                through our Site. Our Site’s functionality can be used without
                revealing any Personal Data, but for features or Services
                related to the Content Oﬀerings, <b>Personal Data</b> may be
                required. In order to access certain features and benefits on
                our Site, you may need to submit, or we may collect, "Personal
                Data" (i.e., information that can be used to identify you and
                which may also be referred to as “personally identifiable
                information” or “personal information”). Personal Data can
                include information such as your name, email address, IP
                address, and device identifier, among other things. You are
                responsible for ensuring the accuracy of the Personal Data you
                submit to Ace PDG. Inaccurate information may aﬀect your ability
                to use the Site, the information you receive when using the
                Site, and our ability to contact you. For example, your email
                address should be kept current because that is one of the
                primary manners in which we communicate with you.
              </p>
              <p>Legal Bases for Collecting and Using Personal Data</p>
              <p>
                We consider that the legal bases for using your Personal Data as
                set out in this Privacy Notice are as follows:
              </p>
              <ul>
                <li>
                  to perform our obligations under any contract with you (for
                  example, to comply with the Terms of Use of our Site which you
                  accept by browsing our website and/or to comply with our
                  contract to provide Services to you, your academic
                  institution, your employer, or other organization that may be
                  providing you with access to our Services)
                </li>
                <li>to comply with our legal obligations;</li>
                <li>
                  for our legitimate interests or the legitimate interests of
                  others. Our legitimate interests are to:
                  <ul>
                    <li>run, grow and develop our business;</li>
                    <li>operate our Site and provide our Services;</li>
                    <li>
                      select appropriately skilled and qualified suppliers;
                    </li>
                    <li>build relationships with partners;</li>
                    <li>carry out research and statistical analysis;</li>
                    <li>carry out marketing and business development; and</li>
                    <li>for internal administrative and auditing purposes.</li>
                  </ul>
                </li>
                <li>
                  where you have given consent, for example, to send you certain
                  communications or where you submit certain information to us,
                  or where required to rely upon consent by local law.
                </li>
              </ul>

              <p>
                Which legal basis applies to a specific activity will depend on
                the type of personal information used and the context in which
                it is being used.
              </p>
              <p>
                If we rely on our (or another party’s) legitimate interests for
                using your personal information, we will undertake a balancing
                test to ensure that our (or the other party’s) legitimate
                interests are not outweighed by your interests or fundamental
                rights and freedoms which require protection of the personal
                information.
              </p>
              <p>
                We may process your personal information in some cases for
                marketing purposes on the basis of your consent (which you may
                withdraw at any time as described below).
              </p>
              <p>
                If we rely on your consent for us to use your personal
                information in a particular way, but you later change your mind,
                you may withdraw your consent by visiting your profile page and
                clicking the box to remove consent and we will stop doing so. If
                you withdraw your consent, this may impact the ability for us to
                provide some of our Services to you.
              </p>
              <p>What Personal Data We Collect</p>
              <p>
                We gather the following types of information about users through
                the Site:
              </p>
              <ol>
                <li>
                  <b>Information relating to your use of our Site.</b>When users
                  come to our Site, we may track, collect, and aggregate
                  information indicating, among other things, which pages of our
                  Site were visited, the order in which they were visited, when
                  they were visited, and which hyperlinks were clicked. We also
                  collect information from the URLs from which you linked to our
                  Site. Collecting such information may involve logging the IP
                  address, operating system, and browser software used by each
                  user of the Site. We may be able to determine from an IP
                  address a user’s Internet Service Provider and the geographic
                  location of their point of connectivity. Location data may be
                  used to route your traﬃc to the nearest regional server to
                  reduce latency and improve your experience. We may also use
                  cookies, web beacons, and other similar technologies when you
                  visit our Site.
                </li>
                <li>
                  <b>
                    Personal Data provided directly by you or via third parties.
                  </b>
                  We collect Personal Data that you provide to us when you
                  register for an account, update or change information for your
                  account, purchase products or Services, complete a survey,
                  sign-up for email updates, participate in our public forums,
                  send us email messages, and/or participate in Content Oﬀerings
                  or other Services on our Site. We may use the Personal Data
                  that you provide to respond to your questions, provide you the
                  specific Content Oﬀering and/or Services you select, send you
                  updates about Content Oﬀerings or other Ace PDG events, and
                  send you email messages about Site maintenance or updates.
                  <ul>
                    <li>
                      Personal Data provided directly by you or via third
                      parties.
                    </li>
                    <li>
                      Updates. Ace PDG may oﬀer you the ability to receive
                      updates either via email or by posting on portions of the
                      Site only accessible to registered users. In order to
                      subscribe to these Services, you may be required to
                      provide us with Personal Data such as your name and email
                      address.
                    </li>
                    <li>
                      Forums. Ace PDG may oﬀer public forums from time to time
                      (the "Forums") where you can share comments and thoughts.
                      In order to participate in the Forums, you may be required
                      to register with us and/or provide us with Personal Data
                      such as your name and email address.
                    </li>
                    <li>
                      Participation in Content Oﬀerings. Ace PDG oﬀers users the
                      opportunity to participate in Content Oﬀerings on or
                      through the Site. If you desire to participate in a
                      Content Oﬀering, you will be asked to provide us with
                      certain information necessary to conduct such a Content
                      Oﬀering. This information may include, among other things,
                      your name and email address.
                    </li>
                  </ul>
                </li>
              </ol>

              <p>
                Communications with Ace PDG. We may receive and record Personal
                Data when you send us an email message or otherwise contact us,
                including through the use of an in-platform messaging/chat
                feature.
              </p>
              <ul>
                <li>
                  Third Party Sites. We may receive Personal Data when you
                  access or log-in to a third party site, e.g., Facebook, from
                  our Sites. This may include text and/or images of your
                  Personal Data available from the third party site.
                </li>
                <li>
                  Surveys. We may receive Personal Data when you provide
                  information in response to a survey.
                </li>
                <li>
                  Third Party Payment Processing. Ace PDG provides you with the
                  ability to pay for Content Oﬀerings and other Services using a
                  credit card or other methods through a third party payment
                  processing service provider. Please note that our service
                  provider – not Ace PDG – collects and processes your financial
                  info.
                </li>
                <li>
                  Device or other IDs for App functionality & Account
                  management.
                </li>
              </ul>
              <p>How We Use Personal Data</p>
              <p>
                Ace PDG collects and uses Personal Data for the purposes
                described in this section. Ace PDG may share such Personal Data
                for these purposes with third parties, such as service
                providers, industry partners, and other commercial partners.
              </p>
              <ul>
                <li>
                  {" "}
                  Providing the Site and our Services. We use Personal Data
                  which you provide to us in order to allow you to access and
                  use the Site and in order to provide any info, products, or
                  Services that you request from us.
                </li>
                <li>
                  Technical support and security. We may use Personal Data to
                  provide technical support to you, where required, and to
                  ensure the security of our Services and the Site.
                </li>
                <li>
                  Updates. We use Personal Data collected when you sign-up for
                  our various email or update services to send you the messages
                  in connection with the Site or Content Oﬀerings. We may also
                  archive this information and/or use it for future
                  communications with you, where we are legally entitled to do
                  so.
                </li>
                <li>
                  Analyzing and improving Site and Services. We use Personal
                  Data, and share such Personal Data with third- party service
                  providers, relating to your use of the Site and Services to
                  record and analyze how learners interact with the Site, its
                  features and content, and our Services. This analysis may
                  involve using techniques such as machine learning, or AI
                  tools. We use this info to better understand demand for Ace
                  PDG’s and Partners’ oﬀerings, inform Ace PDG Site and product
                  developments, and build higher quality, more useful Services.
                </li>
                <li>
                  Participation in Content Oﬀerings. We use the Personal Data
                  that we collect from you when you participate in a Content
                  Oﬀering through the Site for processing purposes, including
                  but not limited to tracking completion of the Content Oﬀerings
                  and results of Quizzes and Mock Exams. We may also use the
                  information generated when taking a Content Oﬀering or using
                  the Services for predictive analysis of your performance in
                  the Content Oﬀerings. Also, we may archive this information
                  and/or use it for future communications with you, where we are
                  legally entitled to do so.
                </li>
                <li>
                  Use of Interactive/Personalized Site Features. We use Personal
                  Data and share such Personal Data with third- party service
                  providers to facilitate and personalize your interaction with
                  Site features such as chatbots, automated translation and
                  grading functionality, tailored recommendations, and learner
                  engagement features. These may utilize machine learning and/or
                  AI tools. Where required or otherwise appropriate, you will be
                  provided with additional information, in advance, about
                  processing of your Personal Data via these features, including
                  the existence of any automated decision-making where relevant
                  (along with meaningful information about the logic involved,
                  as well as the significance and the envisaged consequences of
                  such processing).
                </li>
                <li>
                  Identity Verification. For Services that require identity
                  verification, we may use the Personal Data that we collect for
                  verifying your identity, and for authenticating that
                  submissions made on the Site were made by you. This service
                  may be provided through a third-party identity verification
                  vendor. Your photo identification document will be deleted
                  after successful verification of your profile information.
                </li>
                <li>
                  Communications with or from Ace PDG. When you send us an email
                  message, use in-platform messaging/chat features (which may
                  utilize technology provided by third party service providers
                  contracted by Ace PDG) or otherwise contact us, we and our
                  third-party service providers may record and use the
                  information provided by you to respond to your communication
                  and/or for other purposes described in this Privacy Notice or
                  in other privacy info provided to you during your interaction
                  with our communication systems. We and our third-party service
                  providers may also archive this information and/or use it for
                  future communications with you where we are legally entitled
                  to do so. Where we send you emails, we may track the way that
                  you interact with these emails (such as when you open or click
                  on a link inside an email). We use this information for the
                  purposes of optimizing and better tailoring our communications
                  to you.
                </li>
                <li>
                  Research. We may share general course data (including Quiz or
                  Mock Exam submissions and results), information about your
                  activity on our Site, and demographic data from surveys so
                  that we may use the data for research related to online
                  education.
                </li>
                <li>
                  Marketing and Advertising. We use Personal Data to send
                  marketing and serve advertising, to inform our marketing and
                  advertising strategies and content, to understand more about
                  our audiences and to personalize our marketing and
                  advertising, so that they are more relevant to particular
                  audiences. We may share Personal Data with the providers of
                  third party tools and services for some of these activities,
                  in particular for online advertising purposes, including
                  targeted advertising and custom audience activities, (for
                  example, we use advertising services from Meta, amongst
                  others).
                </li>
                <li>
                  Disclosure to Ace PDG Contractors. We use various third party
                  service providers, vendors, and contractors (collectively,
                  "Contractors") to support Ace PDG activities, including the
                  purposes listed in this section of the Privacy Notice. Our
                  Contractors will have access to Personal Data to the extent
                  necessary for provision of their services to Ace PDG. These
                  Contractors may include vendors and suppliers that provide us
                  with technology, services, and/or content related to the
                  operation and maintenance of the Site or the Content Oﬀerings,
                  or related to Ace PDG’s other business. Access to your
                  Personal Data by these Contractors is limited to information
                  reasonably necessary for the Contractor to perform its limited
                  function for us.
                </li>
                <li>
                  Government Authorities: Ace PDG may share your Personal Data
                  with various government authorities (such as regulatory
                  bodies, law enforcement activities, and national security
                  organizations) in response to lawful subpoenas, court orders,
                  or other legal processes; to establish or exercise our legal
                  rights or to protect our property; to defend against legal
                  claims; or as otherwise required by law. In such cases we
                  reserve the right to raise or waive any legal objection or
                  right available to us.
                </li>
                <li>
                  Legal Rights and Actions: Ace PDG may share your Personal Data
                  with government authorities, external advisors and other
                  relevant or aﬀected third parties when we believe it is
                  appropriate: to investigate, prevent, or take action regarding
                  illegal or suspected illegal activities; to protect and defend
                  the rights, property, or safety of Ace PDG, the Site, our
                  users, customers, or others; and in connection with our Terms
                  of Use and other agreements.
                </li>
                <li>
                  Disclosure to Acquirers. Ace PDG may disclose and/or transfer
                  your Personal Data to an acquirer, assignee or other successor
                  entity in connection with a sale, merger, or reorganization of
                  all or substantially all of the equity, business, or assets of
                  Ace PDG to which your Personal Data relates.
                </li>
                <li>
                  e-Readers. If we receive any Personal Data related to the
                  extent to which you use designated e-Readers or other devices
                  to access Ace PDG materials, we may archive it, and use it for
                  research, business, or other purposes.
                </li>
              </ul>
              <p>External Links</p>
              <p>
                For your convenience we may provide links to sites operated by
                organizations other than Ace PDG ("Third Party Sites") that we
                believe may be of interest to you. We do not disclose your
                Personal Data to these Third Party Sites unless we have a lawful
                basis on which to do so. We do not endorse and are not
                responsible for the privacy practices of these Third Party
                Sites. If you choose to click on a link to one of these Third
                Party Sites, you should review the privacy policy posted on the
                other site to understand how that Third Party Site collects and
                uses your Personal Data.
              </p>
              <p>Retention of Personal Data</p>
              <p>
                We keep your Personal Data for no longer than necessary for the
                purposes for which the Personal Data is collected and processed.
                The length of time we retain Personal Data for depends on the
                purposes for which we collect and use it and/or as required to
                comply with applicable laws and to establish, exercise, or
                defend our legal rights.
              </p>
              <p>Confidentiality & Security of Personal Data</p>
              <p>
                We will use industry standard physical, technical, and
                administrative security measures to keep your Personal Data
                confidential and secure, and will not share it with third
                parties, except as otherwise provided in this Privacy Notice, or
                unless such disclosure is necessary in special cases, such as a
                physical threat to you or others, as permitted by applicable
                law. Because the Internet is not a 100% secure environment, we
                cannot guarantee the security of Personal Data, and there is
                some risk that an unauthorized third party may find a way to
                circumvent our security systems or that transmission of your
                information over the Internet will be intercepted. It is your
                responsibility to protect the security of your login
                information. Please note that e-mail communications are
                typically not encrypted and should not be considered secure.
              </p>
              <p>Your Rights</p>
              <p>
                You have certain rights in relation to your Personal Data. You
                can access your Personal Data and confirm that it remains
                correct and up-to-date, choose whether or not you wish to
                receive material from us or some of our partners, and request
                that we delete or provide you with a copy of your Personal Data
                by emailing{" "}
                <a href="mailto:acepdgapp@gmail.com">acepdgapp@gmail.com</a>.
              </p>
              <p>
                We try to answer every request promptly where possible, and
                provide our response within the time period stated by applicable
                law. Keep in mind, however, that when deleting your Personal
                Data there may be residual information that will remain within
                our databases, access logs, and other records, which may or may
                not contain your Personal Data. Please also note that certain
                Personal Data may be exempt from such requests in certain
                circumstances, which may include if we need to retain your
                Personal Data to comply with a legal obligation. When you email
                us with a request, we may ask that you provide us with
                information necessary to confirm your identity.
              </p>
              <p>Questions, Suggestions, and Complaints</p>
              <p>
                If you have any privacy-related questions, suggestions,
                unresolved problems, or complaints, you may contact us via
                acepdgapp@gmail.com.
              </p>
              <p>U.S. State Privacy Rights</p>
              <p>Shine the Light</p>
              <p>
                Shine the Light Under California’s "Shine the Light" law,
                California residents who provide personal information in
                obtaining products or services for personal, family, or
                household use are entitled to request and obtain from us, once a
                calendar year, information about the customer information we
                shared, if any, with other businesses for their own direct
                marketing uses. If applicable, this information would include
                the categories of customer information and the names and
                addresses of those businesses with which we shared customer
                information for the immediately prior calendar year (e.g.
                requests made in 2025 will receive information regarding 2024
                sharing activities).
              </p>
              <p>
                To obtain this information, please send an email message to
                acepdgapp@gmail.com with "Request for California Privacy
                Information" on the subject line and in the body of your
                message. We will provide the requested information to you at
                your email address in response. Please be aware that not all
                information sharing is covered by the "Shine the Light"
                requirements and only information on covered sharing will be
                included in our response.
              </p>
              <p>
                To obtain this information, please send an email message to
                acepdgapp@gmail.com with "Request for California Privacy
                Information" on the subject line and in the body of your
                message. We will provide the requested information to you at
                your email address in response. Please be aware that not all
                information sharing is covered by the "Shine the Light"
                requirements and only information on covered sharing will be
                included in our response.
              </p>
              <p>U.S. State Privacy Laws</p>
              <p>
                Under privacy laws of certain U.S. States (“U.S. State Privacy
                Laws”), residents of relevant U.S. States have the right to know
                what personal information about them is collected and how this
                personal information is used and disclosed. Residents of
                relevant U.S. States can also exercise certain rights in respect
                of their data (such as access, deletion, and opting in/out of
                certain disclosures and uses).
              </p>
              <p>International Privacy Practices</p>
              <p>
                Our Site is primarily operated and managed on servers located
                and operated within the United States. In order to provide our
                products and Services to you, you may be sending your Personal
                Data outside of the country where you reside or are located,
                including to the United States. Accordingly, your Personal Data
                may be transferred outside of the country in which you reside or
                are located, including to countries that may not provide the
                same level of protection for your Personal Data. We are
                committed to protecting Personal Data when it is transferred. If
                you reside or are located within the EEA, United Kingdom,
                Switzerland (or other region that oﬀers similar protections) and
                such data transfers occur, we take appropriate steps to ensure
                an equivalent level of protection for your Personal Data. We
                participate in and commit to adhering to the EU-U.S. Data
                Privacy Framework, the UK extension to the EU-U.S. Data Privacy
                Framework, or the Swiss-U.S. Data Privacy Framework when
                transferring data from the EEA, UK or Switzerland to the United
                States. Please see our Data Privacy Framework Notice, below, for
                further information.
              </p>
              <p>Changing Our Privacy Notice</p>
              <p>
                Please note that we review our privacy practices from time to
                time, and that these practices are subject to change. Any
                change, update, or modification will be eﬀective immediately
                upon posting on our Site. We will notify you of any material
                change to this Privacy Notice by posting a notice on our Site’s
                homepage for a reasonable period of time following such update
                or by sending an email to the email address associated with your
                user account, and by changing the eﬀective date (located at the
                top and bottom of this page). Be sure to return to this page
                periodically to ensure familiarity with the most current version
                of this Privacy Notice.
              </p>
              <p>No Information from Children Under 13</p>
              <p>
                Ace PDG strongly believes in protecting the privacy of children.
                Any use or access by anyone under the age of 13 is prohibited,
                and certain regions and Content Oﬀerings may have additional
                requirements and/or restrictions. In line with this belief, we
                do not knowingly collect or maintain Personal Data on our Site
                from persons under 13 years of age, and no part of our Site is
                directed to persons under 13 years of age. If you are under 13
                years of age, then please do not use or access this Site at any
                time or in any manner. We will take appropriate steps to delete
                any Personal Data of persons less than 13 years of age that has
                been collected on our Site without verified parental consent
                upon learning of the existence of such Personal Data.
              </p>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
